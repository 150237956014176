import React from "react";
import Google from "../../../../../assets/img/google.svg";
import Python from "../../../../../assets/img/python.svg";
import ReactJs from "../../../../../assets/img/reactjs.svg";
import Kafka from "../../../../../assets/img/kafka.svg";
import DotNet from "../../../../../assets/img/dot-net.svg";
import Kubernetes from "../../../../../assets/img/kubernetes.svg";
import FiveTran from "../../../../../assets/img/fivetran.svg";
import DataDog from "../../../../../assets/img/datadog.svg";
import Linux from "../../../../../assets/img/linux.svg";
import Kotlin from "../../../../../assets/img/kotlinlang.svg";
import Swift from "../../../../../assets/img/swift.svg";
import { Img } from "react-image";

const TechStack = () => {

    return (
        <div className="career-tech-stack kuda-ignore--container flex justify-center flex-column">
            <h2 className="color-primary careers-h2 mx-auto">Our tech stack</h2>
            <div className="career-techstack-top mx-auto flex justify-between">
                <Img className="stack-1" src={Google} alt="bigquery jobs" />
                <Img className="stack-2" src={Python} alt="python developer jobs" />
                <Img className="stack-1" src={ReactJs} alt="react developer jobs" />
                <Img className="stack-1" src={Kafka} alt="kafka engineer jobs" />
                <Img className="stack-1" src={DotNet} alt=".net core jobs" />
            </div>
            <div className="career-techstack-bottom mx-auto flex justify-between">
                <Img className="stack-1" src={Kubernetes} alt="kubernetes jobs" />
                <Img className="stack-1" src={FiveTran} alt="data integration engineer jobs" />
                <Img className="stack-1" src={DataDog} alt="devops jobs" />
                <Img className="stack-1" src={Linux} alt="linux engineer jobs" />
                <Img className="stack-1" src={Kotlin} alt="kotlin developer jobs" />
                <Img className="stack-1" src={Swift} alt="swift software engineer jobs" />
            </div>
        </div>
    )
}

export default TechStack