import React, { useState } from "react";
import styled from "styled-components";

import playIcon from '../../../../../assets/img/video-play.svg';
import SoundOnIcon from '../../../../../assets/img/soundOn-inline.svg';
import SoundOffIcon from '../../../../../assets/img/soundOff-inline.svg';
import FullScreenIcon from '../../../../../assets/img/size-inline.svg';
import { Img } from "react-image";

import Scroll from "../../../../../assets/img/scrollRight.svg"
import GirlIcon from '../../../../../assets/img/girl-icon.inline.svg';
import GirlsIcon from '../../../../../assets/img/girls-icon.inline.svg';
import NationalitesIcon from '../../../../../assets/img/nationalites.inline.svg';
import Dami from '../.././../../../assets/img/videoPosters/dami.jpg';
import Gaberilla from '../../../../../assets/img/videoPosters/gaberilla.jpg';
import Anietie from '.././../../../../assets/img/videoPosters/anietie.jpg'
import Steffini from '../../../../../assets/img/videoPosters/steffini.jpg';
import Tanisha from '../../../../../assets/img/videoPosters/tanisha.jpg';
import Victor from '../../../../../assets/img/videoPosters/victor.jpg';

const PercentageCard = (props) => {
    return (
        <CardStyle className="">
            <span>
                {props.icon}
            </span>

            <h2 className="card-number--text">{props.numbers}</h2>
            <span className="card--text">{props.text}</span>
        </CardStyle>
    )
}

const DiversityInclusion = () => {
    const kudaVideos = [


        {
            videoSrc: `https://d38v990enafbk6.cloudfront.net/videos/Dami.mp4`,
            posterImg: Dami
        },
        {
            videoSrc: `https://d38v990enafbk6.cloudfront.net/videos/Gabriella.mp4`,
            posterImg: Gaberilla
        },
        {
            videoSrc: `https://d38v990enafbk6.cloudfront.net/videos/Aniette.mp4`,
            posterImg: Anietie
        },
        {
            videoSrc: `https://d38v990enafbk6.cloudfront.net/videos/Stephanie.mp4`,
            posterImg: Steffini
        },

        {
            videoSrc: `https://d38v990enafbk6.cloudfront.net/videos/Tanisha.mp4`,
            posterImg: Tanisha
        },
        {
            videoSrc: `https://d38v990enafbk6.cloudfront.net/videos/Victor.mp4`,
            posterImg: Victor
        },
    ]

    const nextSlide = () => {
        var slider = document.getElementById("slider-2")
        slider.scrollLeft = slider.scrollLeft + 500
    }

    const prevSlide = () => {
        var slider = document.getElementById("slider-2")
        slider.scrollLeft = slider.scrollLeft - 500
    }

    const [muted, setMuted] = useState(true);

    const playVideo = (i) => {
        const video = document.getElementById(`video${i}`);

        if (document.fullscreenElement) {
            videoFullScreenMode(video, i)
        }

        if (!document.fullscreenElement) {
            videoNotFullScreenMode(video, i)
        }
    }

    const videoFullScreenMode = (video, i) => {

        if (!video.paused) {
            document.querySelector(`.fa-play${i}`).style.display = "block";
            video.play();
            setMuted(true);
        }
        else {
            document.querySelector(`.fa-play${i}`).style.display = "none";
            video.pause()
            setMuted(false)
        }

    }

    const videoNotFullScreenMode = (video, i) => {
        if (video.paused) {
            document.querySelector(`.fa-play${i}`).style.display = "none"
            video.play()
            setMuted(false)
        }
        else {
            document.querySelector(`.fa-play${i}`).style.display = "block"
            video.pause()
            setMuted(true)
        }
    }

    const handleFullScreen = (i) => {
        const video = document.getElementById(`video${i}`);
        video.requestFullscreen()
    }

    const handleKeyPress = (e, i) => {
        const video = document.getElementById(`video${i}`);

        // for spacebar and enter key
        if (e.keyCode === 13 || e.keyCode === 32) {
            if (!video.paused) {
                document.querySelector(`.fa-play${i}`).style.display = "none";
            }
            else {
                document.querySelector(`.fa-play${i}`).style.display = "block";
            }
        }
    }

    return (
        <DiversityStyled>
            <Inner className="kuda-ignore--container">

                <Heading>
                    <h2 className="diversity-heading-text careers-h2">Diversity and Inclusion at Kuda</h2>
                    <p className="text-center mb-5 mx-auto color-black">
                        We are a people-first organisation that actively promotes a culture of diversity,
                        inclusion, and continuous improvement through open conversation,
                        learning, and an observable commitment to equal opportunities for all.
                    </p>
                </Heading>

                <div className="kuda-testimonials--section mx-auto">
                    <div className="careers-office-image flex">
                        <img className="careers-scroll-button-left desktop-only" src={Scroll} onClick={prevSlide} />
                        <img className="careers-scroll-button-right desktop-only" src={Scroll} onClick={nextSlide} />
                        <div id="slider-2" className="flex careers-scroller">
                            {kudaVideos.map((item, i) =>
                                <div key={item + i} className="careers-video-wrapper">
                                    <div className="careers-video-container">
                                        <video preload="none" poster={item.posterImg} onClick={() => playVideo(i)} onKeyUp={(e) => handleKeyPress(e, i)} loop width="100%" height="100%" className="video" id={`video${i}`}>
                                            <source src={item.videoSrc} type="video/mp4" />
                                        </video>

                                        <div className="control-play-btn">
                                            <button onClick={() => playVideo(i)} className="play-button" type="button">
                                                <Img src={playIcon} className={`fa-play fa-play${i}`} />
                                            </button>
                                        </div>

                                        <div className="control-container">
                                            <button className="dvIvHB control-button sound-button" type="button" onClick={() => setMuted(!muted)}>
                                                <span data-rui="state-layer" className="icon-bg"></span>
                                                <span data-variant="mono" className="control-icon" style={!muted ? { maskImage: `url(${SoundOnIcon})` } : { maskImage: `url(${SoundOffIcon})` }}>
                                                </span>
                                            </button>

                                            <button className="dvIvHB control-button fullscreen-button" type="button" onClick={() => handleFullScreen(i)}>
                                                <span data-rui="state-layer" className="icon-bg"></span>
                                                <span data-variant="mono" className="control-icon" style={{ maskImage: `url(${FullScreenIcon})` }}></span>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <BottomContainer className="mx-auto">
                    <div className="card-row">
                        <PercentageCard icon={<GirlIcon className="card-icon" />} numbers={'61.9%'} text={'Female QA Engineers'} />
                        <PercentageCard icon={<GirlsIcon className="card-icon" />} numbers={'49.8%'} text={'Female Staff members'} />
                        <PercentageCard icon={<NationalitesIcon className="card-icon" />} numbers='9+' text={'Nationalities'} />
                    </div>

                    <DiversityTextContainer>
                        <input type="checkbox" id="readmore" name="readmore" />

                        <div className="diversity-content">
                            <p className="mb-3">Kuda’s main DEI goal is to raise awareness about the differences and commonalities within our diverse workforce.
                                We're taking this beyond just education by having a DEI committee with members from different ethnic backgrounds,
                                genders, age groups, and countries within our Tribe. We also have a platform for our Tribe members to share <label htmlFor='readmore' className="read-more">.. Read more </label>
                                <span>their concerns confidentially as well as an easily accessible inbox for DEI-related inquiries.</span>
                            </p>
                            
                            <p className="mb-3">The members of our DEI committee share personal insights, ideas, and experiences related to DEI in the workplace.
                                They also help the Tribe recognise and address biases that they may encounter or have been made aware of.
                                Through open discussions, the committee aims to facilitate education and foster mutual respect among all members of the Tribe,
                                ultimately preventing future issues.</p>

                            <p>Our efforts in promoting DEI have been recognized with a Stevie Award for Great Employers,
                                a testament to our commitment to creating a more inclusive and respectful work environment. <label htmlFor='readmore' className="read-less">Show less</label></p>
                        </div>


                    </DiversityTextContainer>
                </BottomContainer>

            </Inner>

        </DiversityStyled>
    )
}

const CardStyle = styled.div`
    text-align: center;
    height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-number--text{
        font-size: 36px;
        font-family: Mulish;
        line-height: 50px;
        font-weight: 900;
        color: var(--primaryColor);
    }

    .card--text{
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        color: var(--blackColor);
    }

    @media only screen and (max-width: 767px) {
        .card-number--text{
            font-size: 28px;
        }

        .card--text{
            font-size: 14px;
        }
    }
`
const DiversityStyled = styled.div`
    margin-top: 160px;
`
const Inner = styled.div`
    background-color: #F9F9F9;
    padding: 80px 0px;

    .kuda-testimonials--section {
        max-width: 85vw;
    }

    .careers-office-image{
        position: relative;

        .careers-scroll-button-left {
            position: absolute;
            left: -30px;
            top: 30%;
        }
        .careers-scroll-button-right {
            position: absolute;
            right: -5px;
            top: 30%;
        }
    }
    #slider-2{
        gap: 45px;
    }

    .careers-scroller{

        &::-webkit-scrollbar{
            width: 0px !important;
            background: none;
        }
        &::-webkit-scrollbar-thumb{
            background: none;
        }
    }

    .careers-video-wrapper{
        max-width: 100%;
        height: auto;
    }
    .careers-video-container {
        width: 462px;
        max-width: 100%;
        height: 100%;
        margin-top: 0px;
        position: relative;

        &:hover .control-container {
            opacity: 1;
        }
        
    }

    .careers-video-container  img {
        width: 100%;
        height: auto; 
    }

    .video{
        object-fit: cover;
        display: flex;
        border-radius: 0.75rem;
        width: 100%;
        height: 100%;
    }

    .control-play-btn{
        position: absolute;
        width: 100%;
        top: 42.5%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity .2s;  
        
        .fa-play{
            max-width: 60px;
            max-height: 60px;
        }
    }

    .play-button{
        background: none;
        cursor: pointer;
    }

    .control-container{
        opacity: 0;
        position: absolute;
        width: 100%;
        top: 1.5rem;
        right: 1.5rem;
        gap: 0.375rem;
        transition: opacity .2s;  
        box-sizing: border-box;
        margin: 0px;
        padding: 0px;
        font: inherit;
        display: flex;   
        justify-content: end;
        
        .dvIvHB:enabled:not([aria-disabled="true"]) {
            cursor: pointer;
        }
        
        .control-button {
            -webkit-box-align: center;
            align-items: center;
            border-radius: 25%;
            color: var(--whiteColor);
            cursor: pointer;
            display: flex;
            height: 32px;
            -webkit-box-pack: center;
            justify-content: center;
            transition: background-color 300ms cubic-bezier(0.15,0.5,0.5,1);
            width: 36px;

            &:hover{
                background-color: rgba( 64, 25, 109, 0.7);
                isolation: auto;
            }
        }
        .dvIvHB {
            box-sizing: border-box;
            border: none;
            font: inherit;
            color: inherit;
            text-align: inherit;
            text-decoration: none;
            outline-color: rgb(64, 25, 109);
            background-color: rgba( 64, 25, 109, 0.3);
            border-radius: 25%;
            padding: 0px;
            margin: 0px;
            position: relative;
            isolation: isolate;
        }
        
        
    }

    .control-button .icon-bg {
        position: absolute;
        inset: 0px;
        display: block;
        border-radius: inherit;
        transition: inherit;
        background-color: #8369A0;
        pointer-events: none;
        z-index: -1;
    }

    .control-icon[data-variant="mono"] {
        background-color: currentcolor;
        mask-repeat: no-repeat;
        mask-size: 100% 100%;
    }
    
    .control-icon, .control-size-icon {
        width: 20px;
        height: 20px;
        display: block;
        flex: 0 0 auto;
    }
    
    @media only screen and (max-width: 767px) {
    
        .control-play-btn .fa-play{
                max-width: 50px;
                max-height: 50px;
        }
        
        .control-container{
            top: 1.5rem;
            right: 1.5rem;
        }
    }

    @media only screen and (max-width: 425px) {
        .control-play-btn {
            top: 36.5%;

            .fa-play {
                max-width: 45px;
                max-height: 45px;
            }
        }
    }

`
const Heading = styled.div`
    text-align: center;
    max-width: 80vw;
    margin-right: auto;
    margin-left: auto;

    .diversity-heading-text {
        color: var(--primaryColor);
        margin-top: 160px;
        margin-bottom: 12px;
    }

    p{
        max-width: 505px;
        width: 100%;
    }

    .careers-h2{
        margin-top: 0px !important;
    }

`

const BottomContainer = styled.div`
    margin-top: 120px;
    max-width: 535px;

    .card-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    @media only screen and (max-width: 767px) {

        .card-row{
            align-items: center;
            flex-direction: column;
            gap: 35px;
        }
    }
`

const DiversityTextContainer = styled.section`
    margin-top: 3rem;

    .diversity-content{
        overflow: hidden;
        max-height: 135px;
        transition: 0.5s ease-in-out;
        position:relative;
        text-align: center;
    
    
        p{
            font-family: Mulish;
            line-height: 26px;
            color: var(--blackColor);
            font-size: 16px;
        }

        &::before{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50px;
            transition: 0.5s ease-in-out;
            content: "";
        }
    }

    input[name="readmore"]{
        display: none;
    }
  
   .read-more, .read-less{
        color: var(--primaryColor);
        cursor: pointer;
        font-weight: 800;
        position: relative;
   }

   input[name="readmore"]:checked ~ .diversity-content{
        max-height: 600px;
   }
   input[name="readmore"]:checked ~ .diversity-content::before{
        height: 0px;
   }

   input[name="readmore"]:checked ~ .diversity-content .read-more{
        display: none;
   }

   @media only screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: auto;
        margin-left: auto;
       
    }

    @media only screen and (max-width: 576px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 88vw;
        margin-right: auto;
        margin-left: auto;

        .diversity-content{
            max-height: fit-content;

            p{
                font-size: 15px;
            }

            .read-more, .read-less{
                display: none;
            }
        }
    }

    @media only screen and (max-width: 425px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90vw;
        margin-right: auto;
        margin-left: auto;

        .diversity-content p{
                font-size: 13px;
                line-height: 22px;
            }
        }
    }
`


export default DiversityInclusion;
