import React from "react";
import Picture1 from '../../../../../assets/img/partyPics1.jpg'
import Picture2 from '../../../../../assets/img/partyPics2.jpg'
import Picture3 from '../../../../../assets/img/partyPics3.jpg'
import Picture4 from '../../../../../assets/img/partyPics4.jpg'
import Picture5 from '../../../../../assets/img/partyPics5.jpg'
import Picture6 from "../../../../../assets/img/office-1.jpg"
import Picture7 from "../../../../../assets/img/office-2.jpg"
import Picture8 from "../../../../../assets/img/office-3.jpg"
import Picture9 from "../../../../../assets/img/office-4.jpg"
import Picture10 from "../../../../../assets/img/office-5.jpg"
import Picture11 from "../../../../../assets/img/office-6.jpg"



import Scroll from "../../../../../assets/img/scrollRight.svg"

const PictureAtKuda = () => {
    const kudaImages = [
        Picture1,
        Picture2,
        Picture3,
        Picture4,
        Picture5,
        Picture6,
        Picture7,
        Picture8,
        Picture9,
        Picture10,
        Picture11
    ]

    const nextSlide = () => {
        var slider = document.getElementById("slider")
        slider.scrollLeft = slider.scrollLeft + 500
    }

    const prevSlide = () => {
        var slider = document.getElementById("slider")
        slider.scrollLeft = slider.scrollLeft - 500
    }

    return (
        <div className="kuda-section careers-margin-top kuda-ignore--container">
            <div className="kuda-section--inner">
                <div className="kuda-section--100">
                    <div className="kuda-testimonial--wrap">
                        <div className="text-center careers-picture-text-container">
                            <h2 className="kuda-section--heading careers-h2 careers-video-header text-center text-xlbold color-primary title-bottom--spacing">
                                Picture yourself at Kuda
                            </h2>
                            <p className="text-center mb-5 mx-auto color-black">
                                See our people, explore our spaces.
                            </p>
                        </div>
                        <div className="kuda-testimonials--section">
                            <div className="careers-office-image flex">
                                <img className="careers-scroll-button-left desktop-only" src={Scroll} onClick={prevSlide} />
                                <img className="careers-scroll-button-right desktop-only" src={Scroll} onClick={nextSlide} />
                                <div id="slider" className="flex careers-scroller">
                                    {kudaImages.map((item, i) =>
                                        <div key={item + i} className="office-svg">
                                            <img src={item} alt="kuda office space images" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PictureAtKuda
