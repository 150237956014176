import React from "react";

const EqualOpportunity = () => {
    return (
        <div className="kuda-footer--conditions careers-bottom-text">
            <p>
                Kuda is proud to be an Equal Opportunity employer and we are committed to ensuring equal employment and promotion opportunities to all qualified candidates and employees. All qualified applicants will be equally considered for employment regardless of sex, gender identity, sexual orientation, racial or ethnic origin, color, religion, nationality, disability, age, or any other characteristics protected by law when applying at Kuda.
            </p>
        </div>
    )
}

export default EqualOpportunity