import React from "react";

const MissionImageTextLeft = (props) => {
    return (
        <div className="kuda-section">
            <div className="kuda-section--inner flex justify-between align-center">
                <div className="kuda-section--50">
                    <div>
                        {props.quotation}
                    </div>
                    <div className="kuda-section--wrap mx-auto">
                        <h2 className="kuda-section--heading careers-h2 color-primary title-bottom--spacing">
                            {props.title}
                        </h2>
                        <p className="color-black kuda-section--max">
                            {props.subtitle}
                        </p>
                    </div>
                </div>
                <div className="kuda-section--50">
                    <div className="kuda-section--illustration">
                        {props.illustration}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MissionImageTextLeft;
