import React from "react";
import Olubusola from "../../../../../assets/img/employeeTestimonial/olubusola.jpg"
import Aniete from "../../../../../assets/img/employeeTestimonial/aniete.jpg"
import Tolani from "../../../../../assets/img/employeeTestimonial/tolani.jpg"
import Faith from "../../../../../assets/img/employeeTestimonial/faith.jpg"
import Ikenna from "../../../../../assets/img/employeeTestimonial/ikenna.jpg"
import Scott from '../../../../../assets/img/employeeTestimonial/Scott.jpg'
import Caamila from '../../../../../assets/img/employeeTestimonial/Caamila.jpg'
import Karlin from '../../../../../assets/img/employeeTestimonial/Karlin.jpg'
import Mukaram from '../../../../../assets/img/employeeTestimonial/Mukaram.jpg'
import Mfoniso from '../../../../../assets/img/employeeTestimonial/Mfoniso.jpg'

import { Img } from "react-image";

const EmployeeTestimonials = () => {
    const testimonials = [
        {
            icon: Olubusola,
            text: "I believe that the best part of being at Kuda is the people. They make up a huge part of my success story.",
            moreText: "Kuda gives you an opportunity to remain true to yourself while doing what you love with the most amazing people.",
            name: "Olubusola Odusami",
            jobRole: "Head of HR - Nigeria",
        },
        {
            icon: Karlin,
            text: "My experience at Kuda has been incredible. The teamwork and friendly environment make every day enjoyable.",
            moreText: "My colleagues make me feel valued and supported, and I'm constantly learning and growing in my role. The culture at Kuda is fantastic. It's a place where I feel proud to work.",
            name: "Karlin Wan",
            jobRole: "Senior HR & People Operations Manager"
        },

        {
            icon: Aniete,
            text: "My experience at Kuda has been amazing. Everyone matters at Kuda. Everyone puts in the work daily, and is treated with Love & Respect and given the opportunity to grow.",
            moreText: "I like that Kuda is very intentional with its core values. Kuda has made it a place to be for the last 3 years.",
            name: "Anietie Victor",
            jobRole: "Senior Process Analyst",
        },
        {
            icon: Tolani,
            text: "Working at Kuda has been one of the most rewarding experiences of my professional life.",
            moreText: "Kuda places an adequate focus on employee engagement, happiness, well being and productivity which in turn boosts work-life balance. In addition, the people at Kuda are amazing!",
            name: "Tolani Onokwai",
            jobRole: "Senior QA Engineer",
        },
        {
            icon: Scott,
            text: "Since I joined a couple of years ago, Kuda is fast paced, vibrant, dynamic and fun. So much going on, so much to do but the most important aspect is the people.",
            moreText: "I work with lots of supportive, innovative, interesting and inspiring people who have trusted me and helped me to grow (alongside with Kuda).",
            name: "Scott Hirst",
            jobRole: "Lead Product Manager - Growth"
        },
        {
            icon: Caamila,
            text: "Since joining Kuda in 2023, I've experienced a dynamic and innovative environment where teams adapt swiftly to technological advancements.",
            moreText: "Open communication, regular meetings, and an open-door policy foster transparency, enabling us to freely share insights, feedback, and even occasional memes. It's refreshing.",
            name: "Caamila Harris",
            jobRole: "Accounts Payable Specialist"
        },

        {
            icon: Faith,
            text: "My experience at Kuda has been very enlightening. I love the tribe, the Kuda culture, the exposure, the learning experiences I’ve had, the push to become better.",
            moreText: "Here at Kuda, I am encouraged to speak up, read widely, research, and participate in projects. In less than three months, I can say that I am a better person at my career.",
            name: "Faith Ekabua",
            jobRole: "Service Monitoring Officer"
        },
        {
            icon: Ikenna,
            text: "My experience at Kuda has been an educational and inspiring journey. Simplicity is the very first lesson I can remember since I started.",
            moreText: "At Kuda, there is flexibility which gives you time for family obligations and personal matters. Kuda has an open-door policy where you can share your ideas, which is something I love.",
            name: "Ikenna Nwankpa",
            jobRole: "IS Administrator"
        },
        {

            icon: Mfoniso,
            text: "My journey at Kuda has been inspiring and progressive. Beginning as a customer support specialist, I've advanced to Deputy Team Lead over four years.",
            moreText: "I appreciate the enthusiasm that working at Kuda brings. There's a strong sense of respect, order, and ownership among everyone, which makes work smooth.",
            name: "Mfoniso Orok",
            jobRole: "Deputy Team Lead"
        },
        {
            icon: Mukaram,
            text: "My time at Kuda has been exceptional, marked by warmth and camaraderie.",
            moreText: "The focus on personal and professional development, through training and mentorship, has driven my growth. I'm grateful for the dynamic culture of collaboration, empowerment, and inclusivity at Kuda.",
            name: "Mukaram Agboola",
            jobRole: "Senior Supervisor, Chargeback Operations"
        },

    ]

    return (
        <div className="kuda-section careers-margin-top kuda-ignore--container" >
            <div className="kuda-section--inner">
                <div className="kuda-section--100">
                    <div className="kuda-testimonial--wrap">
                        <h2 className="kuda-section--heading text-center careers-h2 color-primary title-bottom--spacing">
                            Employee Testimonials
                        </h2>
                        <div className="kuda-testimonials--section">
                            <div className="careers-kuda-testimonial--cards">
                                {testimonials.map((item, i) => {
                                    return (
                                        <div key={i} className="card card-item flex flex-column justify-between">
                                            <div className="content">
                                                <p className="testimonial-text color-black text-sm">{item.text}</p>
                                                <p className="testimonial-text color-black text-sm mt-2">{item.moreText}</p>

                                            </div>
                                            <div className="testimonial-extras mt-0 flex align-center">
                                                {item.icon !== "" ?
                                                    <Img src={item.icon} alt="kuda Microfinance Bank Testimonials" />
                                                    :
                                                    <div className="kuda-no--image">
                                                        {item.name.charAt(0)}
                                                    </div>
                                                }
                                                <div>
                                                    <p className="text-sm text-xlbold color-black">{item.name}</p>
                                                    <p className="text-xs">{item.jobRole}</p>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                }

                                )}

                                {testimonials.map((item, i) =>

                                    <div key={i} className="card card-item flex flex-column justify-between">

                                        <div className="content">
                                            <p className="testimonial-text color-black text-sm">{item.text}</p>
                                            <p className="testimonial-text color-black text-sm mt-2">{item.moreText}
                                            </p>

                                        </div>

                                        <div className="testimonial-extras mt-0 flex align-center">
                                            {item.icon !== "" ?
                                                <Img src={item.icon} alt="kuda Microfinance Bank Testimonials" />
                                                :
                                                <div className="kuda-no--image">
                                                    {item.name.charAt(0)}
                                                </div>
                                            }
                                            <div>
                                                <p className="text-sm text-xlbold color-black">{item.name}</p>
                                                <p className="text-xs">{item.jobRole}</p>
                                            </div>
                                        </div>

                                    </div>

                                )}


                            </div>
                        </div>

                    </div>
                </div>
            </div >

        </div >
    )
}



export default EmployeeTestimonials;

