import React from "react";

const FAQ = ({ faqs, toggleFAQ }) => {
    return (
        <div className="faqs kuda-career-container">
            <div className="kuda-career-bottom-margin3">
                <h2 className="kuda-section--heading text-center careers-h2 color-primary kuda-career-bottom-margin2">
                    Recruitment FAQs
                </h2>
            </div>
            
            {faqs?.map((faq, i) =>
                <div
                    className={`faq ${faq.open ? "open" : ""}`}
                    key={i}
                    onClick={() => toggleFAQ(i)}
                >
                    <div className="faq-question color-primary">
                        <p>{faq.question}</p>
                    </div>
                    <div className="faq-answer color-black">
                        {faq.answer}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FAQ;