import React from "react";
import GroupIllustration from "../../../../../assets/img/Kuda-Love.svg"
import { Link } from "gatsby";
import { Img } from "react-image";

const Header = (props) => {

    return (
        <div className="kuda-entry--fold home-fold header-background kuda-ignore--container career-noMarginTop" >
            <div className="kuda-entry--inner flex justify-between align-center mx-auto career-container-width" >
                <div className="kuda-section--50 career-header-padding">
                    <div className="kuda-main--wrap careers-kuda-main--wrap">
                        <h1 className="careers-h1 kuda-main--heading careers-text-center text-xlbold careers-mb-mobile color-primary title-bottom--spacing">
                            {props.title}
                        </h1>
                        <p className="card-main--heading text-semi-bold careers-text-center careers-mb2-mobile color-black title-bottom--spacing">
                            {props.subtitle}
                        </p>
                        
                        <div className="kuda-view-jobs">
                                <Link to={"/careers/view-jobs/?location="} className="kuda-cta kuda-cta-long">
                                    View all jobs
                                </Link>     
                        </div>
                    </div>
                </div>
                <div className="kuda-section--50">
                    <div className={`kuda-illustration--center careers-iillustration-margin careers-mt-mobile hasAnimation`}>
                        <Img src={GroupIllustration} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Header;
