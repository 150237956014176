import React from "react";

const ImageAndTextLeft = (props) => {
    return (
        <div className="kuda-section">
            <div className="kuda-section--inner careers-image-text-mobile careers-image-text flex justify-between align-center">
                <div className="kuda-section--60">
                    <div className="kuda-section--wrap careers-kuda-section--wrap">
                        <div>
                            {props.quotation}
                        </div>
                        <h2 className="kuda-section--heading careers-h2 color-primary title-bottom--spacing">
                            {props.title}
                        </h2>
                        <p className="color-black kuda-section--max">
                            {props.subtitle}
                        </p>
                    </div>
                </div>
                <div className="kuda-section--40">
                    <div className="kuda-section--illustration">
                        {props.illustration}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageAndTextLeft;
