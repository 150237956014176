import React, { useState } from "react";
import { Link, navigate } from "gatsby";

const Jobs = ({ heading, subheading, locations, moreLocations }) => {
    const [showMoreLocations, setShowMoreLocations] = useState(false)

    const handleClick = (pathQuery) => {
        navigate(`/careers/view-jobs/?location=${pathQuery}`)
    }

    const handleShowLocation = () => {
        setShowMoreLocations(!showMoreLocations)
    }

    return (
        <div className="kuda-career-container">
            <div className="kuda-career-bottom-margin4">
                <h2 className="kuda-section--heading text-center careers-h2 color-primary kuda-career-bottom-margin2">
                    {heading}
                </h2>
                <p className="text-center color-black">
                    {subheading}
                </p>
            </div>

            <div className="kuda-below--inner">
                <div className="kuda-summary--cards flex summary-top justify-between">
                    {locations?.map((item, i) =>
                        <div key={i} className="card career-card card-item card-mobile" onClick={() => handleClick(item.country)}>
                            <div className="card-icon--spacing">
                                {item.icon}
                            </div>
                            <div>
                                <p className="text-sm mb-1 card-text-mobile">{item.city}</p>
                                <p className="color-black card-text-mobile1 text-xbold">{item.country}</p>
                            </div>
                        </div>)
                    }
                </div>
                {showMoreLocations &&
                    <div className="kuda-summary--cards flex flex-wrap summary-top justify-between">
                        {moreLocations?.map((item, i) =>
                            <div key={i} className={`card career-card card-item card-mobile`} onClick={() => handleClick(item.country)}>
                                <div className="card-icon--spacing">
                                    {item.icon}
                                </div>
                                <div>
                                    <p className="text-sm mb-1 card-text-mobile">{item.city}</p>
                                    <p className="color-black card-text-mobile1 text-xbold">{item.country}</p>
                                </div>
                            </div>)
                        }
                    </div>
                }
            </div>

            <div className="kuda-job-opening flex">
                <div className="kuda-cta-white mr-4 kuda-cta careers-kuda-cta-long" onClick={handleShowLocation}>
                    {showMoreLocations ? "View less locations" : "View all locations"}
                </div>
                <Link to={"/careers/view-jobs/?location="} className="kuda-cta careers-kuda-cta-long">
                    View all openings
                </Link>
            </div>
        </div>
    )
}

export default Jobs