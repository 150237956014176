import React from "react"
import CareersLandingPage from "../components/body/pages/careers"
import LayoutCareers from "../components/layoutCareers"
import SEO from "../components/seo"

const Careers = () => (
  <LayoutCareers>
    <SEO
      canonical={"https://kuda.com/careers/"}
      title="Fintech Jobs | Tech Support, DevOps, iOS & Android Dev | Kuda"
      description="Kuda is making financial services accessible for all Africans on the planet. With tech, support & entry-level positions available, join the tribe today."
    />
    <CareersLandingPage />
  </LayoutCareers>
)

export default Careers
