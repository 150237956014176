import React, { useState } from "react";
import styled from "styled-components";
import playIcon from '../../../../../assets/img/video-play.svg';
import SoundOnIcon from '../../../../../assets/img/soundOn-inline.svg';
import SoundOffIcon from '../../../../../assets/img/soundOff-inline.svg';
import FullScreenIcon from '../../../../../assets/img/size-inline.svg';
import { Img } from "react-image";
import PosterImage from '../../../../../assets/img/videoPosters/life-at-kuda.jpg';

const LifeAtKudaVideo = () => {
    const [muted, setMuted] = useState(true);

    const playVideo = (e) => {

        const video = document.getElementById('video');

        if (document.fullscreenElement) {
            videoFullScreenMode(video);
        }

        if (!document.fullscreenElement) {
            videoNotFullScreenMode(video)
        }

    }

    const videoFullScreenMode = (video) => {

        if (video.paused) {
            video.pause()
            setMuted(false)
            document.querySelector(".fa-play").style.display = "none";
        }
        else {
            video.play();
            setMuted(true);
            document.querySelector(".fa-play").style.display = "block";
        }

    }

    const videoNotFullScreenMode = (video) => {
        if (!video.paused) {
            video.pause()
            setMuted(true)
            document.querySelector(".fa-play").style.display = "block";

        }
        else {
            video.play()
            setMuted(false)
            document.querySelector(".fa-play").style.display = "none";
        }
    }


    const handleFullScreen = () => {
        const video = document.getElementById('video');
        video.requestFullscreen();
    }

    const handleKeyPress = (e) => {
        const video = document.getElementById('video');

        // for spacebar and enter key
        if (e.keyCode === 13 || e.keyCode === 32) {
            if (!video.paused) {
                document.querySelector(".fa-play").style.display = "none";
            }
            else {
                document.querySelector(".fa-play").style.display = "block";
            }
        }
    }

    return (
        <KudaVideoStyled>
            <div className="text-center careers-video-text-container">
                <h2 className="careers-video-header careers-h2">Life at Kuda</h2>
                <p className="mx-auto color-black">
                    Here’s what some of our tribe members have to say about working at Kuda.
                </p>
            </div>

            <div className="careers-video-container mx-auto">
                <video preload="none" poster={PosterImage} onClick={() => playVideo()} onKeyUp={(e) => handleKeyPress(e)} loop width="100%" height="100%" className="video" id="video">
                    <source src="https://d38v990enafbk6.cloudfront.net/videos/Life-at-Kuda.mp4" type="video/mp4" />
                </video>

                <div className="control-play-btn">
                    <button onClick={() => playVideo()} className="play-button" type="button">
                        <Img src={playIcon} className="fa-play" />
                    </button>
                </div>

                <div className="control-container">
                    <button className="dvIvHB control-button sound-button" type="button" onClick={() => setMuted(!muted)}>
                        <span data-rui="state-layer" className="icon-bg"></span>
                        <span data-variant="mono" className="control-icon" style={!muted ? { maskImage: `url(${SoundOnIcon})` } : { maskImage: `url(${SoundOffIcon})` }}>
                        </span>
                    </button>

                    <button className="dvIvHB control-button fullscreen-button" type="button" onClick={handleFullScreen}>
                        <span data-rui="state-layer" className="icon-bg"></span>
                        <span data-variant="mono" className="control-icon" style={{ maskImage: `url(${FullScreenIcon})` }}></span>
                    </button>

                </div>
            </div>
        </KudaVideoStyled>
    )
}

const KudaVideoStyled = styled.div`

    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font: inherit;

  
    .careers-video-container {
        margin-top: 48px;
        width: 848px;
        height: 477px;
        position: relative;

        &:hover .control-container {
            opacity: 1;
        }
    }

    .video{
        object-fit: cover;
        display: flex;
        border-radius: 0.75rem;
    }

    .control-play-btn{
        position: absolute;
        width: 100%;
        top: 43.5%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity .2s;  
        
        .fa-play{
            max-width: 70px;
            max-height: 70px;
            display: block;
        }
    }

    .play-button{
        background: none;
        cursor: pointer;
    }

    .control-container{
        opacity: 0;
        position: absolute;
        width: 100%;
        top: 2.5rem;
        right: 2.5rem;
        gap: 0.375rem;
        transition: opacity .2s;  
        box-sizing: border-box;
        margin: 0px;
        padding: 0px;
        font: inherit;
        display: flex;   
        justify-content: end;
        
        .dvIvHB:enabled:not([aria-disabled="true"]) {
            cursor: pointer;
        }
        
        .control-button {
            -webkit-box-align: center;
            align-items: center;
            border-radius: 25%;
            color: var(--whiteColor);
            cursor: pointer;
            display: flex;
            height: 32px;
            -webkit-box-pack: center;
            justify-content: center;
            transition: background-color 300ms cubic-bezier(0.15,0.5,0.5,1);
            width: 36px;

            &:hover{
                background-color: rgba( 64, 25, 109, 0.7);
                isolation: auto;
            }
        }
        .dvIvHB {
            box-sizing: border-box;
            border: none;
            font: inherit;
            color: inherit;
            text-align: inherit;
            text-decoration: none;
            outline-color: rgb(64, 25, 109);
            background-color: rgba( 64, 25, 109, 0.3);
            border-radius: 25%;
            padding: 0px;
            margin: 0px;
            position: relative;
            isolation: isolate;
        }
        
        
    }

    .control-button .icon-bg {
        position: absolute;
        inset: 0px;
        display: block;
        border-radius: inherit;
        transition: inherit;
        background-color: #8369A0;
        pointer-events: none;
        z-index: -1;
    }

    .control-icon[data-variant="mono"] {
        background-color: currentcolor;
        mask-repeat: no-repeat;
        mask-size: 100% 100%;
    }
    
    .control-icon, .control-size-icon {
        width: 20px;
        height: 20px;
        display: block;
        flex: 0 0 auto;
    }

  
    @media only screen and (min-width: 768px) and (max-width: 1024px){
        .careers-video-container {
            width: 668px;
            height: 400px;
        }
    }

    @media only screen and (max-width: 767px) {
        .careers-video-container {
           width: 100%;
           height: 100%;
        }

        .control-play-btn .fa-play{
                max-width: 50px;
                max-height: 50px;
        }
        
        .control-container{
            top: 1.5rem;
            right: 1.5rem;
        }
    }

    @media only screen and (max-width: 425px) {
        .control-play-btn {
            top: 36.5%;

            .fa-play {
                max-width: 45px;
                max-height: 45px;
            }
        }
    }

`
export default LifeAtKudaVideo
