import React from "react";
import Love from "../../../../../assets/img/love.svg"
import Kindness from "../../../../../assets/img/empathy 1.svg"
import Respect from "../../../../../assets/img/respect 3.svg"
import Transparent from "../../../../../assets/img/transparent 1.svg"
import Customers from "../../../../../assets/img/customer's first 1.svg"
import Collaboration from "../../../../../assets/img/collaboration.svg"
import Accountability from "../../../../../assets/img/accountabilty.svg"
import Quality from "../../../../../assets/img/Quality 2.svg"
import Dots from "../../../../../assets/img/values-dot.svg"
import Dots2 from "../../../../../assets/img/values-dot2.svg"
import DotsMobile from "../../../../../assets/img/dot-mobile.svg"
import { Img } from "react-image";


const ValuesGrid = () => {

    return (
        <div className="color-primary kuda-career-container">
            <div className="text-center flex flex-column align-center">
                <h2 className="careers-h2 kuda-career-bottom-margin2">
                    Our values
                </h2>
                <p className="color-black kuda-value-subheader">We're passionate about our values and a great work culture.</p>
            </div>

            <div className="kuda-values-grid">

                <div className="kuda-careers-values1 kuda-values-grid-colspan-2 kuda-values-col-position-1 kuda-values-grid-item">
                    <h3 className="careers-h3 kuda-career-bottom-margin">Love</h3>
                    <p className="color-black text-sm">
                        Kuda means love, and love is at the centre of everything we do.
                    </p>
                    <Img className="kuda-values-img1" src={Love} />
                </div>

                <div className="kuda-careers-values2 kuda-values-grid-colspan-2 kuda-values-grid-rowspan-1 kuda-values-grid-item">
                    <h3 className="careers-h3 kuda-career-bottom-margin">Kindness And <br /> Empathy</h3>
                    <p className="color-black text-sm">
                        Everyone we meet deserves our kindness. We empathise with people so we can help them.
                    </p>
                    <Img className="kuda-values-img2" src={Kindness} />
                </div>

                <div className="kuda-careers-values3 kuda-values-grid-colspan-2 kuda-values-grid-item">
                    <h3 className="careers-h3 kuda-career-bottom-margin">Respect</h3>
                    <p className="color-black text-sm">
                        When we interact with people outside Kuda, we give them the same respect we give our coworkers and ourselves.
                    </p>
                    <Img className="kuda-values-img3" src={Respect} />
                </div>

                <div className="kuda-careers-values4 kuda-values-grid-colspan-2 kuda-values-grid-rowspan-2 kuda-values-col-position-1 kuda-values-grid-item">
                    <h3 className="careers-h3 kuda-career-bottom-margin">Quality Over Quantity</h3>
                    <p className="color-black text-sm">
                        We don’t believe in busywork and spinning wheels. When we put out work, it’s the best quality we can muster.
                    </p>
                    <Img className="kuda-values-img4" src={Quality} />
                </div>

                <div className="kuda-careers-values5 kuda-values-grid-colspan-2 kuda-values-grid-item">
                    <h3 className="careers-h3 kuda-career-bottom-margin">Transparency</h3>
                    <p className="color-black text-sm">
                        We don’t hide or attempt to embelish the truth about who we are, what we believe and how we feel.
                    </p>
                    <Img className="kuda-values-img5" src={Transparent} />
                </div>

                <div className="kuda-careers-values4 kuda-values-grid-colspan-2 kuda-values-grid-rowspan-2 kuda-values-grid-item">
                    <h3 className="careers-h3 kuda-career-bottom-margin">Customer First</h3>
                    <p className="color-black text-sm">
                        We put customers’ issues ahead of everything else. Nothing is right for us until we have made things right with our customers.
                    </p>
                    <Img className="kuda-values-img6" src={Customers} />
                </div>


                <div className="kuda-careers-values2 kuda-values-grid-colspan-3 kuda-values-grid-item kuda-values-grid-item2">
                    <h3 className="careers-h3 kuda-career-bottom-margin">Collaboration</h3>
                    <p className="color-black text-sm">
                        The fastest way for us to build products and <br /> features our customers love is to work together <br /> as a team and with those customers.
                    </p>
                    <Img className="kuda-values-img7-1" src={Dots} />
                    <img className="kuda-values-img7" src={Collaboration} />
                </div>

                <div className="kuda-careers-values1 kuda-values-grid-colspan-3 kuda-values-grid-item kuda-values-grid-item2">
                    <h3 className="careers-h3 kuda-career-bottom-margin">Accountability & <br /> Ownership</h3>
                    <p className="color-black text-sm">
                        Kuda is a workplace that promotes <br /> leadership, and everyone is accountable for <br /> the work they do.
                    </p>
                    <Img className="kuda-values-img8-1 desktop-only" src={Dots2} />
                    <Img className="kuda-values-img8" src={Accountability} />
                    {/* <img className="kuda-values-img8 mobile-only" src={QualityMobile} /> */}
                    <Img className="kuda-values-img8-1 mobile-only" src={DotsMobile} />
                </div>
            </div>
        </div>
    )
}

export default ValuesGrid