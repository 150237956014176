import React, { useEffect, Fragment, useState } from "react"
import { Link } from "gatsby"
import { scrollToElement } from "../../../utility/utils"
import Header from "./components/header"
import MissionIllustration from "../../../../assets/img/mission-illustration.svg"
import CEO from "../../../../assets/img/CEO.svg"
import CTO from "../../../../assets/img/musty-cto.png"
import Pension from "../../../../assets/img/pensionIcon.svg"
import AnnualLeave from "../../../../assets/img/annualLeave.svg"
import CareerDevelopment from "../../../../assets/img/careerDev.svg"
import HybridWork from "../../../../assets/img/hybrid.svg"
import Learning from "../../../../assets/img/learningAndDev.svg"
import Birthday from "../../../../assets/img/birthday.svg"
import Growth from "../../../../assets/img/growth.svg"
import More from "../../../../assets/img/more.svg"
import ImageAndTextLeft from "./components/imageAndTextLeft"
import ImageAndTextRight from "./components/imageAndTextRight"
import Cards from "./components/cards"
import EmployeeTestimonials from "./components/employeeTestimonials"
import ValuesGrid from "./components/valuesGrid"
import FAQ from "./components/FAQ's"
import NgFlag from "../../../../assets/img/NGflag.svg"
import UgFlag from "../../../../assets/img/UGflag.svg"
import UkFlag from "../../../../assets/img/UKflag.svg"
import SaFlag from "../../../../assets/img/SAflag.svg"
import GermanyFlag from "../../../../assets/img/germany.svg"
import SerbiaFlag from "../../../../assets/img/serbia.svg"
import BulgariaFlag from "../../../../assets/img/bulgaria.svg"
import EgyptFlag from "../../../../assets/img/egypt.svg"
import TurkeyFlag from "../../../../assets/img/turkey.svg"
import PortugalFlag from "../../../../assets/img/portugal.svg"
import CyprusFlag from "../../../../assets/img/cyprus.svg"
import UaeFlag from "../../../../assets/img/uae.svg"
import PolandFlag from "../../../../assets/img/poland.svg"
import QuotationMark from "../../../../assets/img/quotation-marks.svg"
import Jobs from "./components/jobOpenings"
import TechStack from "./components/techStack"
import LifeAtKudaVideo from "./components/lifeAtKuda"
import PictureAtKuda from "./components/pictureAtKuda"
import EqualOpportunity from "./components/equalOpportunityText"
import MissionImageTextLeft from "./components/MissionImageTextLeft"
import OurAwards from "./components/ourAwards"
import Vanguard from '.././../../../assets/img/vanguardIcon.svg'
import GageIcon from '.././../../../assets/img/gageIcon.svg'
import AfricaSummit from '.././../../../assets/img/africa-summit.svg'
import GRCIcon from '.././../../../assets/img/grcIcon.svg'
import DiversityInclusion from "./components/diversityAndInclusion"

const CareersLandingPage = () => {
  const entryContent = {
    title: "Work at Kuda.",
    subtitle: (
      <span>
        Join Africa's Fastest Growing Fintech Company.
      </span>
    ),
  }

  const kudasMission = {
    title: "Kuda’s mission",
    subtitle: (
      <span className="mb-4">
        We’re on a mission to make financial services accessible, affordable and rewarding for all Africans on the planet.      </span>
    ),
    illustration: <img src={MissionIllustration} />,
  }

  const ceoMessage = {
    title: "Message from CEO",
    subtitle: (
      <div className="career-ceo-message">
        <p className="mb-4">
          "Kuda has always been about having faith in people, and we're building
          a company where anyone who dreams of making a positive impact in the
          world will feel supported to bring that dream to life.
        </p>
        <p className="mb-4">
          So if you're a big dreamer with a collaborative mindset and the skills
          we’re looking for, you'll find a like-minded tribe at Kuda. This is
          where you belong."
        </p>
        <p className="color-primary mb-4"><span className="text-xlbold">Babs Ogundeyi -</span> Founder & Group CEO</p>
      </div>
    ),
    illustration: <img className="mb-3" src={CEO} />,
    quotation: <img src={QuotationMark} />,
  }

  const ctoMessage = {
    title: "Message from CTO",
    subtitle: (
      <div className="career-ceo-message">
        <p className="mb-4">
          "Kuda is inspired to make the financial lives of Africans around the
          world easier through technology.
        </p>
        <p className="mb-4">
          We're always on the lookout for
          people who share that inspiration and are excited to devote their time
          to innovating for a better world!
        </p>
        <p className="mb-4">
          There's room for you here if you're
          that kind of person. Building at Kuda could be your next big move!"
        </p>
        <p className="color-primary mb-4">
          <span className="text-xlbold">Musty Mustapha -</span> Co-Founder & CTO
        </p>
      </div>
    ),
    illustration: <img className="mb-3 career-figure-size" src={CTO} />,
    author: <p className="color-primary"><span className="text-xlbold">Musty Mustapha -</span> Co-Founder & CTO</p>,

  }

  const heading = {
    heading: "Benefits",
    subheading: (
      <span className="color-black">
        Beyond a competitive salary, we offer perks and <br /> resources to help our team thrive.
      </span>
    ),
    mobile: (
      <span>
        We care about all tribe members and <br /> offer a great range of
        benefits.
      </span>
    ),
  }

  const topCards = [
    {
      icon: <img src={Pension} />,
      text: "Life Assurance",
      content: "We will automatically enroll you into our pension scheme.",
    },
    {
      icon: <img src={AnnualLeave} />,
      text: "Paid Annual Leave",
      content:
        "You’ll get time off work, during which you’ll receive your normal pay.",
    },
    {
      icon: <img src={CareerDevelopment} />,
      text: "Career Development",
      content:
        "We’ll support your professional growth.",
    },
  ]

  const centerCards = [
    {
      icon: <img src={HybridWork} />,
      text: "Hybrid Work Model",
      content:
        "You’ll have full autonomy to meet your goals at home and at the office.",
    },
    {
      icon: <img src={Learning} />,
      text: "Learning And Development",
      content:
        "You’ll have full access to Kuda Academy, our learning and development platform.",
    },
    {
      icon: <img src={Birthday} />,
      text: "Birthday Off Work",
      content: "You'll get a day off to celebrate your birthday. ",
    },
  ]

  const bottomCards = [
    {
      icon: <img src={Growth} />,
      text: "Growth",
      content:
        "We have two reviews annually. Even if you’re the best in the world, you’ll get an honest review to help you be even better.",
    },
    {
      icon: <img src={More} />,
      text: "More",
      content: "Additional benefits to be provided when joining the Kuda tribe.",
    },
  ]


  const [faqs, setFaqs] = useState([
    {
      question: "What's the best way to apply for a position at Kuda?",
      answer: (
        <p>
          Just head over to our{" "}
          <Link
            to={"/careers/view-jobs/?location="}
            className="link-decoration color-primary"
          >
            {" "}
            careers page
          </Link>{" "}
          where we post all of our open positions
        </p>
      ),
      open: false,
    },
    {
      question: "Can I apply by email?",
      answer: (
        <p>
          Although we love to speak and connect with each one of our candidates directly, emails go into the spam folder so the best way to apply to any of our positions is via our{" "}
          <Link
            to={"/careers/view-jobs/?location="}
            className="link-decoration color-primary"
          >
            {" "}
            careers page{" "}
          </Link>
        </p>
      ),
      open: false,
    },
    {
      question: "Do you offer entry-level positions?",
      answer: (
        <p>
          Yes, we have many positions ranging from entry level to up to senior
          level. We post all of these positions on our{" "}
          <Link
            to={"/careers/view-jobs/?location="}
            className="link-decoration color-primary"
          >
            {" "}
            careers page
          </Link>{" "}
          once they’re available
        </p>
      ),
      open: false,
    },
    {
      question: "Where are Kuda's offices located?",
      answer:
        "Our offices are based in Lagos, Cape Town, London, plus more to come. Watch this space",
      open: false,
    },
    {
      question: "Are you able to sponsor visas?",
      answer:
        "Unfortunately this is not something that we can offer at this stage",
      open: false,
    },
    {
      question: "I'm a recruiter, can we work together?",
      answer:
        "We’re not looking for external  support across recruitment. All of our hiring is done inhouse by our Talent Acquisition team",
      open: false,
    },
    {
      question: "Does Kuda foster Diversity, Equity & Inclusion?",
      answer:
        `Yes, Kuda has a DEI Committee comprised of members from diverse nationalities. 
       This committee's responsibilities include educating the Tribe on DEI matters and addressing all related queries. 
       Encouraging a work environment that is inclusive and embraces diversity is crucial.`,
      open: false,
    },
  ])

  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open
        } else {
          faq.open = false
        }

        return faq
      })
    )
  }

  const JobLocations = [
    {
      icon: <img src={SaFlag} />,
      city: "Capetown",
      country: "South Africa",
    },
    {
      icon: <img src={NgFlag} />,
      city: "Lagos",
      country: "Nigeria",
    },
    {
      icon: <img src={UkFlag} />,
      city: "London",
      country: "United Kingdom",
    },
    {
      icon: <img src={UgFlag} />,
      city: "Kampala",
      country: "Uganda",
    },
  ]

  const MoreJobLocations = [
    {
      icon: <img src={GermanyFlag} />,
      city: "Berlin",
      country: "Germany",
    },
    {
      icon: <img src={SerbiaFlag} />,
      city: "Belgrade",
      country: "Serbia",
    },
    {
      icon: <img src={BulgariaFlag} />,
      city: "Sofia",
      country: "Bulgaria",
    },
    {
      icon: <img src={EgyptFlag} />,
      city: "Cairo",
      country: "Egypt",
    },
    {
      icon: <img src={TurkeyFlag} />,
      city: "Istanbul",
      country: "Turkey",
    },
    {
      icon: <img src={PortugalFlag} />,
      city: "Lisbon",
      country: "Portugal",
    },
    {
      icon: <img src={CyprusFlag} />,
      city: "Limassol",
      country: "Cyprus",
    },
    {
      icon: <img src={UaeFlag} />,
      city: "Dubai",
      country: "United Arab Emirates",
    },
    {
      icon: <img src={PolandFlag} />,
      city: "Warsaw",
      country: "Poland",
    },
  ]

  const awardTopCards = [
    {
      icon: <img src={AfricaSummit} />,
      text: "Kuda wins Excellence in Digital Banking Award at 2023 AFTSS Awards",
      linkTo: `https://guardian.ng/business-services/kuda-wins-2023-aftss-excellence-in-digital-banking-award/`
    },
    {
      icon: <img src={GageIcon} />,
      text: "Kuda wins Innovative Bank of the Year at GAGE Awards",
      linkTo: `https://x.com/GageAwards/status/1535702108468822021?lang=en`
    },

  ]
  const awardBottomCards = [    
    {
      icon: <img src={Vanguard} />,
      text: "Kuda wins Vanguard Fintech of the Year Awards",
      linkTo: `https://www.vanguardngr.com/2022/05/vanguard-awards-kuda-bank-broadening-banking-access-with-innovation/`
    },
    {
      icon: <img src={GRCIcon} />,
      text: "Kuda wins 2023 GRC Employer of the Year (Fintech)",
      linkTo: `https://grcfincrimeawards.com/winners/2023?2023`
    },
  ]
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <Header title={entryContent.title} subtitle={entryContent.subtitle} />

      <MissionImageTextLeft
        title={kudasMission.title}
        subtitle={kudasMission.subtitle}
        illustration={kudasMission.illustration}
      />

      <ImageAndTextRight
        subtitle={ceoMessage.subtitle}
        illustration={ceoMessage.illustration}
        quotation={ceoMessage.quotation}
      />

      <ImageAndTextLeft
        subtitle={ctoMessage.subtitle}
        illustration={ctoMessage.illustration}
        quotation={ceoMessage.quotation}
      />

      <div id="values">
        <ValuesGrid />
      </div>

      <div id="benefits">
        <Cards
          heading="Benefits"
          subheading={heading.subheading}
          subheadingMobile={heading.mobile}
          topFeatures={topCards}
          centerFeatures={centerCards}
          bottomFeatures={bottomCards}
        />
      </div>


      <Jobs
        heading="Job openings"
        subheading="Here are some locations we work from."
        locations={JobLocations}
        moreLocations={MoreJobLocations}
      />
      <div id="tech-stack">
        <TechStack />
      </div>

      <LifeAtKudaVideo />

      <DiversityInclusion />
      <div id="awards">
        <OurAwards
          heading="Our awards"
          topFeatures={awardTopCards}
          centerFeatures={awardBottomCards}
        />
      </div>

      <PictureAtKuda />
      <EmployeeTestimonials />

      <FAQ faqs={faqs} toggleFAQ={toggleFAQ} />

      <EqualOpportunity />
    </Fragment>
  )
}

export default CareersLandingPage
